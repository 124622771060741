/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, PriceWrap, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ceník"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="mt--30 pb--0 pt--0" name={"cenik"}>
          
          <ColumnWrap className="column__flex --shape4 --center el--2 pb--60 pl--0 pr--0 pt--50 flex--top" anim={null} animS={null} style={{"backgroundColor":"var(--color-custom-1)"}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"} style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--left fs--62 title-box--invert" content={"Ceník"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null} style={{"maxWidth":510}}>
              
              <Text className="text-box text-box--left text-box--invert pl--06" content={"Cenu ovlivňuje:<br>typ mytých ploch, míra znečištění, objem zakázky, jednorázové / pravidelné, přístup k plochám, výška, použitá technika...."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"cenik-2"}>
          
          <ColumnWrap className="column__flex el--2 pb--06 pl--02 pr--02 pt--06" style={{"maxWidth":1400}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"}>
              
              <Title className="title-box" content={"Mytí oken, výloh a žaluzií<br>"}>
              </Title>

              <Text className="text-box" content={"Oboustranné mytí všech oken včetně rámů a vnitřních i vnějších parapetů.<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pt--0">
              
              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={"Okna jednoduchá bez šroubování ( jedna skleněná tabule)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"od 30,-Kč /m2&nbsp;"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={"Okna dvoudílná, špaletová, šroubovací"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"od 45,-Kč /m2"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={"Výlohy"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"od 30,-Kč/m2"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={"Venkovní žaluzie"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"od 30,-Kč/m2"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <Text className="text-box" content={"<span style=\"text-decoration-line: underline;\">Minimální cena zakázky 1500,-Kč</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Přirážka 30% - 100%\n</span><br>Mytí oken na nepřístupných místech / po stavbě příplatek 50 - 100%\n<br>Mytí&nbsp; oken nemytých více jak rok - příplatek 30%&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--2 mt--60 pb--06 pl--02 pr--02 pt--06" style={{"maxWidth":1400}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"}>
              
              <Title className="title-box" content={"&nbsp;Vybělení PVC rámů oken"}>
              </Title>

              <Text className="text-box" content={"Speciální a velmi účinné vybělení všech plastových částí oken, parapetů, dveří a dalších ploch z tvrdého bílého PVC."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pt--0">
              
              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={"Vnější / vnitřní čištění PVC"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"od 30,-Kč/m2"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Lze objednat pouze k mytí oken</span><br><span style=\"text-decoration-line: underline;\">Minimální cena zakázky 2500,-Kč</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--2 mt--60 pb--06 pl--02 pr--02 pt--06" style={{"maxWidth":1400}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"}>
              
              <Title className="title-box" content={"Mytí velkých ploch"}>
              </Title>

              <Text className="text-box" content={"Opláštění budov<br>Fasády - skleněné, plastové a další...<br>Mytí reklamních cedulí a poutačů<br>Průmyslové haly a areály......"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pt--0">
              
              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={"Mytí demineralizovanou vodou"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"od 35,-Kč/m2"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Mytí ve výškách</span><br>S využitím speciálních teleskopických tyčí s jemnými kartáči, se dostaneme bezpečně ze země i do těžko přístupných míst ve výšce až 17 metrů!<br><span style=\"text-decoration-line: underline;\">Minimální cena zakázky 5000,-Kč</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--2 mt--60 pb--06 pl--02 pr--02 pt--06" style={{"maxWidth":1400}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"}>
              
              <Title className="title-box" content={"Mytí solárních panelů a FVE"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pt--0">
              
              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={"Přístup ze země&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"od 25,-Kč/m2"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={"Přístup ze střechy / z plošiny"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"od 35,-Kč/m2"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <Text className="text-box" content={"Minimální cena zakázky 5000,-Kč"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--2 mt--60 pb--06 pl--02 pr--02 pt--06" style={{"maxWidth":1400}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"}>
              
              <Title className="title-box" content={"Doprava"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pt--0">
              
              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={"Paušální cena po Praze&nbsp;"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"300,-Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--08 pl--16 pr--16 pt--08" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box w--400" content={"Cena dopravy mimo Prahu"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box w--500" content={"11,-Kč/km"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--0" name={"lw60z5h5vx"}>
          
          <ColumnWrap className="column__flex --shape4 --center el--2 pb--50 pl--0 pr--0 pt--50 flex--top" anim={null} animS={null} style={{"backgroundColor":"var(--color-blend--95)"}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3" anim={"5"} animS={"3"} style={{"maxWidth":510}}>
              
              <Title className="title-box title-box--left" content={"Kontaktujte nás a uvidíte svět v novém světle!"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper anim={null} animS={null} style={{"maxWidth":510}}>
              
              <Button className="btn-box btn-box--shape3 btn-box--right" href={"/kontakt"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim2 pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-custom-1)"}} anim={"2"}>
          
          <ColumnWrap className="column__flex el--2 mb--20 mt--10 pb--06 pl--02 pr--02 pt--06" style={{"maxWidth":1400}} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--24 title-box--invert" style={{"maxWidth":""}} content={"Pojďte do toho s námi!"}>
              </Title>

              <Text className="text-box text-box--invert" content={"<span style=\"font-weight: bold;\">Důsledná kontrola\n<br>Férové ceny\n<br>Flexibilita\n<br>Pečlivost</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--24 title-box--invert" style={{"maxWidth":""}} content={"Kontakt"}>
              </Title>

              <Text className="text-box text-box--invert" content={"info@mytiokencz.cz<br>+420 704 434 084&nbsp;<br>"}>
              </Text>

              <Text className="text-box text-box--invert" content={"<a href=\"https://saywebpage.com\">Tvorba web stránek</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}